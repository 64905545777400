.cards {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 6rem;
    gap: 0.75rem;
    background-color: rgb(28, 33, 41);

    @media screen and (max-width: 848px) {
        padding: 4rem 2rem;
    }

    > p {
        text-align: center;

        @media screen and (max-width: 848px) {
            max-width: 24em;
        }

        i {
            color: #fff;
        }
    }

    h2,
    p {
        color: #fff;
        margin: 0;
    }

    &__content {
        display: flex;
        flex-wrap: wrap;
        padding: 1rem 0;
        gap: 2rem;
        width: 100%;
        max-width: 1280px;

        .card {
            width: 100%;
        }

        @media screen and (min-width: 1024px) {
            padding: 2rem 0;

            .card {
                width: calc(100% / 3 - 1.5rem);
            }
        }

        @media screen and (min-width: 600px) and (max-width: 1023px) {
            margin-top: 1rem;
            padding: 0;

            .card {
                width: calc(100% / 2 - 1rem);
            }
        }

        &.meets {
            .card {
                @media screen and (min-width: 1150px) {
                    &:hover {
                        // transform: scale(1.01);

                        .card--hover {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}

.card {
    background: none !important;
    border: 0 !important;
    border-radius: 8px !important;
    width: 250px;
    transition: 0.2s all ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
    position: relative;

    @media screen and (min-width: 1150px) {
        width: 350px;
    }

    @media screen and (max-width: 848px) {
        width: 100%;
    }

    &--likes {
        position: absolute;
        top: 4rem;
        left: 1rem;
        z-index: 3;

        button {
            font-size: 1.2rem;
            border-radius: 30px;
            padding: 0.25rem 1rem;
            color: rgb(0, 0, 0);

            &:hover {
                color: #fff;

                i {
                    color: #fff;
                }
            }
        }
    }

    &--badge {
        position: absolute;
        font-size: 1.2rem;
        color: rgb(255, 255, 255);
        text-transform: uppercase;
        font-weight: 700;
        padding: 0.25rem 1rem;
        border-radius: 30px;
        z-index: 3;

        &:not(.verified) {
            top: 1rem;
            right: 1rem;
            background-color: rgba(0, 0, 0, 0.35);
            backdrop-filter: blur(4px);
            padding: 0.25rem 1rem;
        }

        &.verified {
            display: flex;
            align-items: center;
            justify-content: center;
            top: 1rem;
            left: 1rem;
            background-color: rgb(58, 201, 50);

            i {
                padding-right: 0.5rem;
                color: #fff;
            }
        }
    }

    &__img {
        min-height: 275px;

        &.default {
            border-radius: 8px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            background-color: rgb(51, 76, 96);
            background-size: 50%;
            background-repeat: no-repeat;
            background-position: center;
        }

        img {
            border-radius: 8px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            aspect-ratio: 4 / 3;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        span {
            height: 100%;
            width: 100%;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 1.5rem;
        background: #fff;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        height: 100%;
    }

    &__info {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.25rem;
        flex-wrap: wrap;

        h4 {
            padding-right: 0.5rem;
        }
    }

    &--hover {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: rgba(0, 0, 0, 0.75);
        backdrop-filter: blur(2px);
        height: 100%;
        width: 100%;
        position: absolute;
        z-index: 3;
        border-radius: 8px;
        opacity: 0;
        transition: opacity 0.2s ease-in-out;

        p,
        h4,
        a,
        span {
            color: #fff !important;
        }

        // .content {
        //   top: 0;
        //   background: #fff;
        //   height: 100%;
        //   width: 95%;
        //   margin: 1rem;
        //   border-radius: 8px;
        // }
    }

    h4,
    p {
        color: #000;
        margin: 0;
        // line-height: 1;
    }

    p {
        font-size: 1.4rem;
    }

    .location {
        font-size: 0.875rem;
        color: rgb(255, 255, 255);
        background-color: rgb(28, 33, 41);
        border-radius: 30px;
        padding: 0.25rem 1rem;
        font-weight: 700;
        text-transform: uppercase;
    }

    .contact {
        padding: 0.5rem 0;

        a {
            color: #000;
            text-decoration: none;

            &:hover {
                color: var(--primary);
            }
        }

        i {
            padding-right: 0.5rem;
        }
    }

    .description {
        // height: 10vh;
        // overflow-y: scroll;
        align-items: flex-start;
        justify-content: flex-start;
        text-align: left;

        p {
            padding: 0.5rem 0;
        }
    }

    .date {
        gap: 0.5rem;
        padding-top: 0.5rem;
    }

    .directions {
        display: flex;
        gap: 1rem;
        margin-top: auto;

        button {
            position: relative;
            padding: 0;
            color: rgb(28, 33, 41);

            &:after {
                content: "";
                display: block;
                width: 100%;
                height: 2px;
                background-color: rgb(255, 255, 255);
                transform: scaleX(0);
                transform-origin: left;
                transition: transform 0.2s ease-in-out;
            }

            &:hover {
                background: none;
                color: var(--primary);

                &:after {
                    background-color: var(--primary);
                    transform: scaleX(1);
                }
            }
        }
    }
}

.subscript {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 6rem;

    p {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 0.5rem;
        font-size: 1.2rem;
        text-align: center;
        max-width: 40em;
        font-style: italic;
    }

    .verified {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgb(58, 201, 50);
        padding: 1rem;
        border-radius: 50%;
        width: 15px;
        height: 15px;
        font-size: 1rem;
        color: rgb(255, 255, 255);
        text-transform: uppercase;
        font-weight: 700;
    }
}

.pagination {
    margin: 1.5rem 0;

    button {
        &:hover {
            background: var(--primary);
            color: #fff !important;
        }
    }

    .number {
        background: rgb(150, 69, 69);
        color: rgb(178, 92, 92);

        &.active {
            background: var(--primary);
            color: #fff;
        }
    }

    @media screen and (max-width: 848px) {
        padding: 1rem;
    }
}

.past {
    .card {
        position: relative;

        &--likes,
        .verified,
        .directions {
            display: none;
        }
    }
}

.error {
    margin: 0 auto;

    p {
        color: rgb(221, 178, 86);
        text-transform: uppercase;
        font-weight: 700;
        padding-top: 2rem;
    }
}

select {
    background-color: #fff;
    border-radius: 8px;
    max-width: 250px;
    width: 100%;
    appearance: none;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.25rem;
    border: none;
    padding: 0.5rem 1rem;
    color: #000;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>');
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 1.5rem;
}

.cards-center {
    justify-content: center;
}

.swiper {
    max-width: 400px;
    max-height: 400px;
}

.swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 18px;
    font-size: 22px;
    font-weight: bold;
    color: #fff;
    background-color: white;
    width: 100%;
}

.featured-events {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 4rem 0;
    gap: 3rem;
    background-color: rgb(36, 43, 52);
    border-radius: 8px;
    padding: 4rem 2rem;
    margin: 4rem 0;

    @media screen and (max-width: 848px) {
        grid-template-columns: repeat(1, 1fr);
    }

    > div {
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
    }

    p {
        max-width: 30em;
    }

    .card {
        height: 100%;
        width: 100%;

        &__img {
            min-height: unset;
        }
    }
}
