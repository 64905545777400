:root {
    --primary: rgb(221, 86, 86);
}

html {
    font-size: 62.5%; /* Sets up the Base 10 stuff */
    scroll-behavior: smooth;
}

* {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
    color: #000;
}

a {
    font-size: 1.4rem;
}

p {
    font-size: 1.6rem;
    max-width: 40em;
    margin: 0;

    &.subscript {
        font-size: 1.2rem;
        max-width: unset;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    text-transform: uppercase;
    font-weight: 700 !important;
}

h2 {
    font-size: 3.2rem;
    margin: 0;

    @media screen and (max-width: 848px) {
        font-size: 2.8rem;
    }
}

button {
    border: 0;
    padding: 1rem 2rem;
    font-size: 1.4rem;
    text-transform: uppercase;
    font-weight: 700;
    background: #fff;

    &:hover {
        background: var(--primary);
        color: #fff;
    }

    &:disabled {
        color: rgb(52, 61, 77);
        background-color: rgb(41, 48, 60);
        pointer-events: none;
    }
}

.subheading {
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.4rem;
}

.poppins-thin {
    font-family: "Poppins", sans-serif;
    font-weight: 100;
    font-style: normal;
}

.poppins-extralight {
    font-family: "Poppins", sans-serif;
    font-weight: 200;
    font-style: normal;
}

.poppins-light {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-style: normal;
}

.poppins-regular {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.poppins-medium {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-style: normal;
}

.poppins-semibold {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-style: normal;
}

.poppins-bold {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-style: normal;
}

.poppins-extrabold {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-style: normal;
}

.poppins-black {
    font-family: "Poppins", sans-serif;
    font-weight: 900;
    font-style: normal;
}

.poppins-thin-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 100;
    font-style: italic;
}

.poppins-extralight-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 200;
    font-style: italic;
}

.poppins-light-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-style: italic;
}

.poppins-regular-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: italic;
}

.poppins-medium-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-style: italic;
}

.poppins-semibold-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-style: italic;
}

.poppins-bold-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-style: italic;
}

.poppins-extrabold-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-style: italic;
}

.poppins-black-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 900;
    font-style: italic;
}

.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border-radius: 10px;
    outline: none;
    z-index: 1000;
}

.overlay {
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 999;
}
