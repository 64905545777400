.hero {
    height: 100dvh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.75rem;
    padding: 6rem;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;

    @media screen and (max-width: 848px) {
        padding: 4rem 2rem;
    }

    &::after {
        position: absolute;
        content: "";
        display: block;
        background: rgb(0, 0, 0, 0.3);
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
    }

    &--button {
        position: absolute;
        bottom: 1rem;
        animation: float 1s ease-in-out infinite;
        padding: 1rem;
        z-index: 99;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-self: center;
        transition: 0.2s all ease-in-out;

        &:hover {
            cursor: pointer;
            background-color: rgba(255, 255, 255, 0.1);
            border-radius: 8px;
        }

        @keyframes float {
            0%,
            100% {
                transform: translateY(0);
            }
            50% {
                transform: translateY(-3px);
            }
        }

        i {
            text-align: center;
            text-transform: uppercase;
            font-size: 2rem;
            color: #fff;
            width: 100%;
        }
    }

    &--title {
        font-weight: 700;
        text-transform: uppercase;
        font-size: 2.1rem;
    }

    span,
    p {
        text-align: center;
        color: #fff;
        z-index: 3;
    }

    &--logo {
        width: 100%;
        height: 43px;
        max-width: 250px;
    }

    &--title,
    &--subtle,
    &--subheading,
    &--logo,
    &__content {
        text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
        z-index: 99;
    }

    &--buttons {
        display: flex;
        gap: 1rem;

        @media screen and (max-width: 848px) {
            flex-direction: column;
            gap: 0;
        }

        button {
            &:last-child {
                background: transparent;
                border: 1px solid white;
                color: #fff;

                &:hover {
                    background: var(--primary);
                    border-color: var(--primary);
                }
            }
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        button {
            margin-top: 1rem;
            color: #000;

            &:hover {
                color: #fff;
            }
        }
    }
}

.signout {
    position: fixed;
    top: 1rem;
    right: 1rem;
    z-index: 99;
    border: 0;
    background: rgba(0, 0, 0, 0.463);
    color: #fff;

    &:hover {
        background: black;
        color: fff;
    }
}
