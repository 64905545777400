.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    gap: 0.75rem;
    background: #fff;
    
    &--logo {
        max-width: 175px;
    }

    &--logo-sm {
        max-width: 75px;
    }

    ul {
        padding: 0;
        margin: 0;
        gap: 0.5rem;

        li {
            color: #000;
            text-align: center;
            list-style: none;
        }
    }

    .small-badges {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
    }

    .resized {
        max-width: 18px;
    }

    .react {
        width: 18px;
        height: 18px;
        object-fit: contain;
    }

    .netlify {
        width: 18px;
        height: 18px;
    }

    .supabase {
        width: 75px;
        height: 15px;
    }
}